<template>
    <v-card>
        <codemirror ref="myCm"
                :value="code" 
                :options="cmOptions"
                @ready="onCmReady"
                @focus="onCmFocus"
                @input="onCmCodeChange">
        </codemirror>

    </v-card>

</template>

<script>
import { codemirror } from 'vue-codemirror'
import dedent from 'dedent'
import 'codemirror/lib/codemirror.css'


  export default {
    name: 'HelloWorld',
    components: {
        codemirror
    },
    data () {
      return {
        code: dedent`
        url: https://fe0kwiy9k5.apigw.ntruss.com/mapi/mapi/33IwPIRtwd/json
        method: POST
        body: json 형식
       { 
            "c_code": "", //DB c_code
            "m_code": "", //DB m_code
            "l_code": "", //DB l_code
            "g_code": "", //DB g_code
            "nc_code": "", //DB nc_code
            "options": [], // 선택한 옵션코드(type Array[string])
            "month": "036",//개월수 = 36개월:036, 48개월:048 60개월:060
            "km": "003",  //약정거리 = 1만: 001, 2만:003 3만: 005
            "deposit": "0", //보증금 = 없음:0,10%:10,20%:20,30%:30
            "apay": "30",  //선수금 = 없음:0,10%:10,20%:20,30%:30
            "totalAmt": 0, //만원단위: 차량기본가+옵션가격 
            "optionAmt": 0, //옵션합계가격 만원단위
            "modelAmt": 0,  //차량기본가
            "detail": false // 결과 상세보기
        }

        결과 detail=false로 요청시
         {
            success: true | false,
            message: '오류시 내용',
            data: {
                rent: [],
                lease: []
            }
        }

        결과 detail=true로 요청시
        {
            success: true | false,
            message: '오류시 내용',
            data: {
                estimate3: [], //lease
                estimate4: []  //rent
                ...기타
            }
        }

       
        `,
      
          cmOptions: {
            // codemirror options
            tabSize: 4,
            mode: 'text/javascript',
            theme: 'base16-dark',
            lineNumbers: true,
            line: true,
            // more codemirror options, 更多 codemirror 的高级配置...
          }
        }
      }
    }
   
  //}
  

</script>
