<template>
  <div>
    <CarCategory @onchange="onchange" />
        <v-tabs v-model="tab">
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tab-2">
                견적
            </v-tab>
             <v-tab href="#tab-3">
                옵션설명
            </v-tab>

        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item value="tab-2">
                <v-row justify="space-between">
                    <v-col cols="12" md="6">
                        <v-alert
                        dense
                        border="left"
                        type="warning"
                        >
                        아래 옵션에는 동시 선택/해제 등이 구현되어 있지 않습니다
                        </v-alert>
                        <v-card>
                            <v-card-text>
                                기본가격: {{ addcomma(payload.modelAmt) }}만원<br />
                                옵션가격: {{ addcomma(payload.optionAmt) }}만원<br />
                                합계가격: {{ addcomma(payload.totalAmt) }}만원
                            </v-card-text>
                            <v-list 
                            flat
                            subheader
                            three-line
                            >
                            <v-list-item-group
                               
                                multiple
                                active-class=""
                            >

                                <v-list-item v-for="(item,index) in detail.options" :key="index" >
                                    <template >
                                        <v-list-item-action>
                                        <v-checkbox  v-model="payload.options" :value="item.OPTION_CD" @change="valueChanged( item.OPTION_CD)"></v-checkbox>
                                        </v-list-item-action>

                                        <v-list-item-content>
                                        
                                        <v-list-item-title>{{item.OPTION_NM}}</v-list-item-title>
                                        <v-list-item-subtitle >code:{{item.OPTION_CD}} | gcode:{{item.G_CODE}} | e1:{{item.EVENT_CD_ADD1}} | e2:{{item.EVENT_CD_ADD1}} | e3:{{item.EVENT_CD_ADD3}} | e4:{{item.EVENT_CD_ADD4}}</v-list-item-subtitle>
                                        <v-list-item-subtitle >{{item.OPTION_RMRK}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            {{item.ADD_OPTION_AMT}}만원
                                        </v-list-item-action>
                                    </template>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                        
                    </v-card>
                    <v-card>
                        <v-card-text>
                            <v-radio-group
                                v-model="payload.month"
                                row
                            >
                            <template v-slot:label>
                                <div>이용기간</div>
                            </template>
                            <v-radio
                                label="36개월"
                                value="036"
                            ></v-radio>
                            <v-radio
                                label="48개월"
                                value="048"
                            ></v-radio>
                            <v-radio
                                label="60개월"
                                value="060"
                            ></v-radio>
                            </v-radio-group>
                            <v-radio-group
                                v-model="payload.km"
                                row
                            >
                                <template v-slot:label>
                                    <div>약정거리</div>
                                </template>
                                <v-radio
                                    label="연1만km"
                                    value="001"
                                ></v-radio>
                                <v-radio
                                    label="연2만km"
                                    value="003"
                                ></v-radio>
                                <v-radio
                                    label="연3만km"
                                    value="005"
                                ></v-radio>
                                <v-radio
                                    label="무제한"
                                    value="999"
                                ></v-radio>
                            </v-radio-group>
                            <v-radio-group
                             v-model="payload.deposit"
                             row
                            >
                                <template v-slot:label>
                                    <div>보증금</div>
                                </template>
                                <v-radio
                                    label="없음"
                                    value="0"
                                ></v-radio>
                                <v-radio
                                    label="10%"
                                    value="10"
                                ></v-radio>
                                <v-radio
                                    label="20%"
                                    value="20"
                                ></v-radio>
                                <v-radio
                                    label="30%"
                                    value="30"
                                ></v-radio>
                            </v-radio-group>
                            <v-radio-group
                             v-model="payload.apay"
                             row
                            >
                                <template v-slot:label>
                                    <div>선수금</div>
                                </template>
                                <v-radio
                                    label="없음"
                                    value="0"
                                ></v-radio>
                                <v-radio
                                    label="10%"
                                    value="10"
                                ></v-radio>
                                <v-radio
                                    label="20%"
                                    value="20"
                                ></v-radio>
                                <v-radio
                                    label="30%"
                                    value="30"
                                ></v-radio>
                            </v-radio-group>
                        </v-card-text>
                    </v-card>
                    <v-btn color="primary" block @click="realtimeEstimate">
                            실시간 견적내기
                    </v-btn>

                    </v-col>
                    <v-col cols="12" md="6">
                        <v-tabs v-model="tabes">
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab href="#tab-estimate">
                                실시간 견적결과
                        </v-tab>
                        <v-tab href="#tab-payload">
                                payload
                        </v-tab>

                        </v-tabs>
                        <v-tabs-items v-model="tabes">
                
                            <v-tab-item
                                value="tab-estimate"
                            >
                                 <v-card>
                                    <v-list flat>
                                        <v-subheader>렌트</v-subheader>
                                        <v-list-item-group

                                            color="primary"
                                        >
                                            <v-list-item
                                            v-for="(item, i) in estimate.rent"
                                            :key="i"
                                            >
                                            <v-list-item-content>
                                                <v-list-item-title>{{item.FINAN_NM}} |  월:{{item.MONTH_PRC}}원 | 인수:{{item.TOTAL_PRC}}원</v-list-item-title>
                                            </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                        </v-list>
                                </v-card>
                                <v-card>
                                    <v-list flat>
                                        <v-subheader>리스</v-subheader>
                                        <v-list-item-group

                                            color="primary"
                                        >
                                            <v-list-item
                                            v-for="(item, i) in estimate.lease"
                                            :key="i"
                                            >
                                            <v-list-item-content>
                                                <v-list-item-title>{{item.FINAN_NM}} |  월:{{item.MONTH_PRC}}원 | 인수:{{item.TOTAL_PRC}}원</v-list-item-title>
                                            </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </v-card>
                            </v-tab-item>
                            <v-tab-item
                                value="tab-payload"
                            >
                            <v-checkbox
                                v-model="payload.detail"
                                label="상세결과보기"
                                ></v-checkbox>
                                 <v-alert
                                    v-if="payload.detail"
                                    dense
                                    border="left"
                                    type="warning"
                                    >
                                    상세견적결과는 개발 참고용이며 실시간견적결과에 보여주지 않습니다
                                    </v-alert>
                                 <v-card>
                                    <v-card-title>요청파라미터</v-card-title>
                                    <v-card-text>
                                    {{payload}}
                                    </v-card-text>
                                </v-card>

                                <v-card>
                                    <PayloadCode />
                                </v-card>

                                <v-divider></v-divider>
                                <v-card>
                                    <v-card-title>견적결과-json</v-card-title>
                                   
                                     <v-card-text>
                                    {{estimate}}
                                    </v-card-text>
                                </v-card>

                               
                            </v-tab-item>

                        
                        </v-tabs-items>

                       
                    </v-col>
                </v-row>
            </v-tab-item>
            <v-tab-item value="tab-3">
               <JSCode />
            </v-tab-item>
        </v-tabs-items>
        <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
  </div>
</template>

<script>
import CarCategory from '@/components/site/modeal/CarCategory'
import JSCode from '@/components/site/modeal/JSCode'
import PayloadCode from '@/components/site/modeal/PayloadCode'
// import CarOptions from '@/components/site/modeal/CarOptions'


  export default {
    name: 'HelloWorld',
    components: {
       
      CarCategory,
      JSCode,
      PayloadCode,
      // CarOptions

    },
    data () {
      return {
        overlay: false,
        tabes: null,
        tab: null,
        selected: [],

        estimate: {
            
        },
        payload: {

            c_code: '',
            m_code: '',
            l_code: '',
            g_code: '',
            nc_code: '',
            options: [],
            month: '036',
            km: '003',
            deposit: '0',
            apay: '30',
            totalAmt: 0,
            optionAmt: 0,
            modelAmt: 0,
            detail: false,
        },
        detail: {
            options: [],
            info: {
                m_amt: '',

            }
        },

      }
    },
    methods: {
        onchange (val) {
           
            
            this.detail = val
             this.$log.warn('m_amt_new', this.detail.info.m_amt,  this.detail)
            this.payload.modelAmt = Number(this.detail.info.m_amt)
            this.payload.totalAmt = this.payload.modelAmt
            this.payload.options = []
            this.payload.c_code =  this.detail.info.c_code
            this.payload.m_code =  this.detail.info.m_code
            this.payload.l_code =  this.detail.info.l_code
            this.payload.g_code =  this.detail.info.g_code
            this.payload.nc_code =  this.detail.info.nc_code
        },
        addcomma(val) {
            var regexp = /\B(?=(\d{3})+(?!\d))/g;
            return val.toString().replace(regexp, ',');

        },
        optionPriceSum(val, checked) {
            if (checked) {
                this.payload.optionAmt += Number(val)
            } else {
                this.payload.optionAmt -= Number(val)
            }
            this.payload.totalAmt = this.payload.modelAmt + this.payload.optionAmt

        },
        valueChanged( val) {
            const _this = this
            let checked = false
            if(_this.payload.options.indexOf(val) !== -1){
                this.$log.warn('선택됨-----------------')
                checked = true
            } else {
                this.$log.warn('선택해제됨-------------')
            }
            this.$log.warn('val', val, checked)
            for (const opt of this.detail.options) {
                if (opt.OPTION_CD === val) {
                    this.optionPriceSum(opt.ADD_OPTION_AMT, checked)
                    this.$log.warn('찾음', opt, opt.EVENT_CD_ADD1.length, _this.payload.options)
                    if (opt.EVENT_CD_ADD1.length  > 0 ) {
                        _this.payload.options =  Array.from(new Set(_this.payload.options.concat(opt.EVENT_CD_ADD1)))
                        
                        // this.$log.warn('찾음2',  _this.selected.concat(opt.EVENT_CD_ADD1))
                        //if(opt.EVENT_CD_ADD1.indexOf(val) !== -1){

                        //}
                    }
                    if (opt.EVENT_CD_ADD2.length  > 0) {
                        _this.payload.options.concat(opt.EVENT_CD_ADD2)
                        //if(opt.EVENT_CD_ADD1.indexOf(val) !== -1){

                        //}
                    }
                }
               // if(opt.EVENT_CD_ADD1.indexOf(val) !== -1){
                // this.$log.warn('val', opt.EVENT_CD_ADD1)
            }
        },
        async realtimeEstimate() {
            if (this.payload.nc_code === '') {
                this.$toasted.show('차량을 선택하세요', {type: 'error'})
                return
            }
            
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(this.payload)
            };
            this.overlay = true
            const response = await fetch("https://fe0kwiy9k5.apigw.ntruss.com/mapi/mapi/33IwPIRtwd/json", requestOptions);

            const rv  = await response.json();
            if (!rv.success) {
                this.$toasted.show(rv.message, {type: 'error'})
                
            } else {
                this.estimate = rv.data
            }
            this.overlay = false
            
            // this.$log.warn('data', this.estimate)
            
            // this.postId = data.id;
        }

    }
  }
  

</script>
