<template>
  <div>
    <v-card>
    <codemirror ref="myCm"
              :value="code" 
              :options="cmOptions"
              @ready="onCmReady"
              @focus="onCmFocus"
              @input="onCmCodeChange">
    </codemirror>
    </v-card>
    <v-card class="mt-10">
      <codemirror ref="myCm"
                :value="code2" 
                :options="cmOptions"
                @ready="onCmReady"
                @focus="onCmFocus"
                @input="onCmCodeChange">
      </codemirror>
    </v-card>
  </div>

</template>

<script>
import { codemirror } from 'vue-codemirror'
import dedent from 'dedent'
import 'codemirror/lib/codemirror.css'


  export default {
    name: 'HelloWorld',
    components: {
        codemirror
    },
    data () {
      return {
        code2: dedent`
        ////// 아래는 모두가딜러 옵션선택 function 이니 필요하면 참고하세요
function ck_add(ojb){
    var json = resultTemplatejsonunescape(ojb.parent().parent().parent().data("addjson"));
    var isView = ojb.parent().parent().parent().parent().find(".option_ck");
    var pView = ojb.parent().parent().parent().parent().parent();
    var add_op_amt = 0;
    var add_op = new Array();

    var opt_name_A = "";
    var opt_name_B = "";
    var opt_name_C = "";
    var opt_name_D = "";
    var opt_name_X = json.OPTION_NM;
    var id_arr = ojb.attr("id").split("_");
    if(ojb.is(":checked")){
        var add_in = json.EVENT_CD_ADD1.split(",");
        var add_out = json.EVENT_CD_ADD2.split(",");

        for(var ai= 0 ; ai < add_in.length ; ai++)
        {
            $("[id ^= '"+id_arr[0]+"_"+id_arr[1]+"_']").each(function(){
                if(ojb.attr("id") != $(this).attr("id") && $(this).data("option_cd") == add_in[ai] && $(this).is(":checked") == false){
                    if(opt_name_A) opt_name_A += ",";
                    opt_name_A += $(this).data("option_nm");
                }
            });
            ojb.parent().parent().parent().parent().find("[data-option_cd='"+add_in[ai]+"']").prop("checked",true);
        }


        for(var ai= 0 ; ai < add_out.length ; ai++)
        {
            $("[id ^= '"+id_arr[0]+"_"+id_arr[1]+"_']").each(function(){
                if(ojb.attr("id") != $(this).attr("id") && $(this).data("option_cd") == add_out[ai] && $(this).is(":checked") == true){
                    if(opt_name_B) opt_name_B += ",";
                    opt_name_B += $(this).data("option_nm");
                }
            });
            ojb.parent().parent().parent().parent().find("[data-option_cd='"+add_out[ai]+"']").prop("checked",false);
        }

        if(opt_name_A){
            opt_infoPop_txt = "<b class='opt_target'>"+opt_name_X+"</b>은(는) ";
            opt_infoPop_txt += "<b class='opt_target'>"+opt_name_A+"</b>이(가) 적용됐을 경우 선택 가능한 옵션입니다.";
            showOptModal(opt_infoPop_txt);
        }else if(opt_name_B){
            opt_infoPop_txt = "<b class='opt_target'>"+opt_name_X+"</b>은(는) ";
            opt_infoPop_txt += "<b class='opt_target'>"+opt_name_B+"</b>(와)과 동시에 선택할 수 없습니다.";
            showOptModal(opt_infoPop_txt);
        }



        // ~ 는 ~ 가 적용됐을 경우 선택가능한 옵션입니다.


        // ~ 는 ~ 과(와)동시에 선택할 수 없습니다.



    }
    else
    {
        var add_in = json.EVENT_CD_ADD3.split(",");
        var add_out = json.EVENT_CD_ADD4.split(",");
        for(var ai= 0 ; ai < add_in.length ; ai++) {
            $("[id ^= '"+id_arr[0]+"_"+id_arr[1]+"_']").each(function(){
                if(ojb.attr("id") != $(this).attr("id") && $(this).data("option_cd") == add_in[ai] && $(this).is(":checked") == false){
                    if(opt_name_C) opt_name_C += ",";
                    opt_name_C += $(this).data("option_nm");
                }
            });
            ojb.parent().parent().parent().parent().find("[data-option_cd='" + add_in[ai] + "']").prop("checked", true);
        }

        for(var ai= 0 ; ai < add_out.length ; ai++){
            $("[id ^= '"+id_arr[0]+"_"+id_arr[1]+"_']").each(function(){
                if(ojb.attr("id") != $(this).attr("id") && $(this).data("option_cd") == add_out[ai] && $(this).is(":checked") == true){
                    if(opt_name_D) opt_name_D += ",";
                    opt_name_D += $(this).data("option_nm");
                }
            });
            ojb.parent().parent().parent().parent().find("[data-option_cd='"+add_out[ai]+"']").prop("checked",false);
        }

        if(opt_name_C){
            /*
            opt_infoPop_txt = "<b class='opt_target'>"+opt_name_X+"</b> ";
            opt_infoPop_txt += "<b class='opt_target'>"+opt_name_C+"</b>중 하나는 반드시 선택해야 합니다.";
            showOptModal(opt_infoPop_txt);
            */
        }else if(opt_name_D){
            opt_infoPop_txt = "<b class='opt_target'>"+opt_name_X+"</b>(을)를 해제하면 ";
            opt_infoPop_txt += "<b class='opt_target'>"+opt_name_D+"</b>도 함께 해제됩니다.";
            showOptModal(opt_infoPop_txt);
        }

        // ~ 중 하나는 반드시 선택해야 합니다.


        // ~ 을 해제하면 ~도 함께 해제됩니다.


    }

    let OPT_ID = "";
    isView.each(function(idx){
        if($(this).is(":checked"))
        {
            if(OPT_ID) OPT_ID += ",";
            OPT_ID += $(this).attr("id").split("_")[2];
            add_op_amt+=~~($(this).data("amt"));
        }
        setCookie("EST_ADD_OPTION",OPT_ID,1);
    })
    pView.find(".prc_a data").html(set_comma(add_op_amt));
    pView.find(".prc_b data").html(set_comma(~~(pView.data("totamt")) + add_op_amt));
    //pView.parent().find(".list_head .price data").html(set_comma(~~(pView.data("totamt")) + add_op_amt));


}

        `,
        code: dedent`
        옵션은 json형식으로 되어 있습니다.

[{
"G_CODE":"001",
"OPTION_CD":"009",//옵션코드
"OPTION_NM":"",//옵션이름
"ADD_OPTION_AMT":"50",//옵션가격 만원단위
"EVENT_CD_ADD1":[],//선택: ~ 는 ~ 가 적용됐을 경우 선택가능한 옵션입니다.
"EVENT_CD_ADD2":[],//선택: ~ 는 ~ 과(와)동시에 선택할 수 없습니다
"EVENT_CD_ADD3":[],//해제: ~ 중 하나는 반드시 선택해야 합니다.
"EVENT_CD_ADD4":[],//해제 ~ 을 해제하면 ~도 함께 해제됩니다.
"OPTION_RMRK":""//옵션설명
}]
*********
EVENT_CD_ADD1,2,3,4는 실제로 아래처럼 되었는데 편의상 array로 만들어서 위에처럼 해놨음

"EVENT_CD_ADD1":"466,",
"EVENT_CD_ADD2":",",
"EVENT_CD_ADD3":",",
"EVENT_CD_ADD4":",",

*****************
아래옵션은 아반떼 LPi 1.6 일반인 스마트옵션의 일부입니다
*****************
[
	
	{
		"G_CODE":"001",
		"OPTION_CD":"467",
		"OPTION_NM":"인포테인먼트 내비",
		"ADD_OPTION_AMT":"70",
		"EVENT_CD_ADD1":["466"],
		"EVENT_CD_ADD2":[],
		"EVENT_CD_ADD3":[],
		"EVENT_CD_ADD4":[],
		"OPTION_RMRK":""
	},
	{
		"G_CODE":"001",
		"OPTION_CD":"466",
		"OPTION_NM":"컨비니언스Ⅰ",
		"ADD_OPTION_AMT":"90",
		"EVENT_CD_ADD1":[],
		"EVENT_CD_ADD2":[],
		"EVENT_CD_ADD3":[],
		"EVENT_CD_ADD4":["467"],
		"OPTION_RMRK":"옵션설명길어서 제거함"
	},
	{기타다른옵션들..}
]

옵션코드 467(인포테인먼트 내비) 보면  EVENT_CD_ADD1:["466"] 값이 있습니다.
클릭시 옵션코드 466도 같이 체크하고 467(인포테인먼트 내비) 해제시 EVENT_CD_ADD3,EVENT_CD_ADD4에는 값이 없으니 자신만 해제합니다.

옵션코드 466(컨비니언스Ⅰ) 보면 선택관련 값이 EVENT_CD_ADD1,EVENT_CD_ADD2 값이 없으니 자신만 체크하고
해제시 EVENT_CD_ADD4에는 467이 존재하니 467(인포테인먼트 내비)도 같이 해제합니다.


        `,
          cmOptions: {
            // codemirror options
            tabSize: 4,
            mode: 'text/javascript',
            theme: 'base16-dark',
            lineNumbers: true,
            line: true,
            // more codemirror options, 更多 codemirror 的高级配置...
          }
        }
      }
    }
   
  //}
  

</script>
